import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';

//importing components
import HomeScreen from './screens/HomeScreen';
import EventScreen from './screens/EventScreen';
import VerifyEmailScreen from './screens/VerifyEmailScreen';
import NewPasswordScreen from './screens/NewPasswordScreen';

//Random stuff to be removed later
import TestingPitch_19_07_23 from './screens/RandomStuffToRemoveLater/TestingPitch_19_07_23';

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomeScreen />} />
      <Route path="/p/:id" element={<EventScreen />} />
      <Route path="/emailverify/:uidb64/:token" element={<VerifyEmailScreen />} />
      <Route path="/resetpassword/:uidb64/:token" element={<NewPasswordScreen />} />
      <Route path="*" element={<HomeScreen />} />
      <Route path="/23408234/TestingPitch/190723" element={<TestingPitch_19_07_23 />} />
    </Routes>
  );
}

export default App;
