import { useState, useEffect, useRef } from 'react';
import '../App.css';
import styled from 'styled-components';

import { useParams } from 'react-router-dom';
import { motion, useScroll, useTransform} from "framer-motion"
import TextInput from '../components/TextInput';


const NewPasswordScreen = () => {

    const { uidb64, token } = useParams();
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");

    const [error1, setError1] = useState("");
    const [error2, setError2] = useState("");

    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);

    const onPressSendNewPassword = () => {
        console.log("uidb64: " + uidb64 + " token: " + token);
        if(password1 === "") {
            setError1("Please enter a password");
            return;
        }
        setError1("");
        if(password1.length < 8) {
            setError1("Password must be at least 8 characters long");
            return;
        }
        if(password1 !== password2) {
            setError2("Passwords do not match");
            return;
        }
        setError2("");
        const sendRequest = async () => {
            try {
                const response = await fetch(`https://api.thenights.app/user_manager/newpassword`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        uidb64: uidb64,
                        token: token,
                        password1: password1,
                        password2: password2,
                    })
                });
                console.log(JSON.stringify(response.status));
                if(response.status === 200) {
                    setShowSuccess(true);
                    setShowError(false);
                }
                else {
                    setShowSuccess(false);
                    setShowError(true);
                }
            }
            catch (err) {
                console.log(err);
            }
        };
        sendRequest();
    }

    return (
        <EverythingWrapper>
            <CenterPanel
                initial={{transform: "translateY(-20vw)", opacity: 0}}
                animate={{transform: "translateY(0vw)", opacity: 1}}
                exit={{transform: "translateY(20vw)", opacity: 0}}
            >
                <CenterPanelContentWrapper>
                    {
                    (!showSuccess && !showError) &&
                    <>
                        <PanelTitle>Enter a new password</PanelTitle>
                        <TextInput
                            type="new-password"
                            placeholder="New Password"
                            error={error1}
                            onChange={(e) => setPassword1(e.target.value)}
                        />
                        <TextInput
                            type="new-password"
                            placeholder="Repeat Password"
                            error={error2}
                            onChange={(e) => setPassword2(e.target.value)}
                        />

                        <RegisterButton onClick={() => onPressSendNewPassword()}>
                            <RegisterButtonText>Reset Password</RegisterButtonText>
                        </RegisterButton>
                    </>
                    }
                    {
                    showSuccess &&
                    <>
                        <PanelTitle>Password reset successful</PanelTitle>
                    </>
                    }
                    {
                    showError &&
                    <>
                        <PanelTitle>An error occured.<br/> Please initiate a new password reset</PanelTitle>
                    </>
                    }
                </CenterPanelContentWrapper>
            </CenterPanel>
        </EverythingWrapper>
    )
}
export default NewPasswordScreen;

const EverythingWrapper = styled.div`
    background-color: #101010;
    witdh: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CenterPanel = styled(motion.div)`
    position: relative;
    background-color: #191919;
    width: 30%;
    min-width: 580px;
    max-width: 620px;
    height: 70%;
    min-height: 800px;
    max-height: 900px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 600px) {
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
`;

const CenterPanelContentWrapper = styled.div`
    width: 85%;
    height: 90%;

    justify-content: center;
    @media (max-width: 600px) {
        width: 80%;
        height: 100%;
        border-radius: 0;
    }
`;

const PanelTitle = styled.h1`
    color: #FF3A47;
    color: #fff;
    font-family: 'Heebo';
    font-weight: 600;
    font-size: 1.5rem;
    width: 100%;
    text-align: left;
`;

const PanelSubtitle = styled.h2`
    color: #fff;
    font-family: 'Heebo';
    font-weight: 400;
    font-size: 1rem;
    width: 100%;
    text-align: left;
`;

const RegisterButton = styled.button`
    margin-top: 50px;
    background-color: #FF3A47;
    width: 100%;
    height: 55px;
    border-radius: 5px;
    border: none;
    color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
`;

const RegisterButtonText = styled.p`
    color: #fff;
    font-family: 'Heebo';
    font-weight: 500;
    font-size: 1rem;
    text-transform: uppercase;
`;