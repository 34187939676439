import '../App.css';
import styled from 'styled-components';


export default function HomeScreen() {
    return (
        <EverythingWrapper>
            <ContentWrapper>
                <Subtitle>W.I.P.</Subtitle>

            { false &&
            <>
                <Subtitle>webversion not available</Subtitle>
                <Title>THE NIGHTS</Title>
                <Text>Download the app</Text>
                <DownloadIconsWrapper>
                    <AppStoreIconWrapper href={"thenights://"}>
                        <AppStoreIcon src={require("../assets/images/icon_appstore.png")} alt="apple store icon" />
                    </AppStoreIconWrapper>
                    <GooglePlayIconWrapper href={"thenights://"}>
                        <GooglePlayIcon src={require("../assets/images/icon_googleplay.png")} alt="google play icon" />
                    </GooglePlayIconWrapper>
                </DownloadIconsWrapper>
            </>
            }

            </ContentWrapper>
        </EverythingWrapper>
    );
}

const EverythingWrapper = styled.div`    
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    background-color: #101010;

    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ContentWrapper = styled.div`
    margin-top: 10vh;
    display: flex;
    height: "100%";
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Subtitle = styled.p`
    font-family: 'Heebo';
    font-weight: 100;
    font-size: 1rem;
    color: #fff;
`;

const Title = styled.p`
    margin-top: 0px;
    font-family: 'NeueHaasGroteskBold';
    font-size: 3rem;
    text-align: center;
    color: #fff;
`;

const Text = styled.p`
    font-family: 'Heebo';
    font-weight: 200;
    font-size: 1.2rem;
    color: #fff;
`;

const DownloadIconsWrapper = styled.div`
    margin-top: 2vh;

    display: flex;
    flex-direction: column;
    align-items: center;

    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const AppStoreIconWrapper = styled.a`
    height: 10vh;
    margin-right: 2vh;
`;

const AppStoreIcon = styled.img`
    height: 100%;
`;

const GooglePlayIconWrapper = styled.a`
    height: 10vh;
`;

const GooglePlayIcon = styled.img`
    height: 100%;
`;