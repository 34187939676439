import React, { useState, useEffect } from "react";

//importing packages
import styled from "styled-components";
import { motion } from "framer-motion";

const TextInput = ({
    type = "text", 
    placeholder = "", 
    value, 
    onChange,
    error = "",
    style = {},
}) =>
{

    const [valueInternal, setValueInternal] = useState(value);
    const [errorInternal, setErrorInternal] = useState(error);

    useEffect(() => {
        setValueInternal(value);
    }, [value]);

    useEffect(() => {
        setErrorInternal(error);
    }, [error]);

    return(
        <TextInputWrapper style={style} >
            <TextField type={type} placeholder={placeholder} value={valueInternal} onChange={(e) => onChange(e)} error={false} />
            {errorInternal !== "" && 
            <motion.div
                initial={{height: 0}}
                animate={{height: "18px"}}
                style={{
                    overflow: "hidden",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <ErrorText>{errorInternal}</ErrorText>
            </motion.div>
            }
        </TextInputWrapper>
    )
}

export default TextInput;

const TextInputWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TextField = styled.input`
    background-color: #292929;
    box-sizing: border-box;
    width: 600px;
    max-width: 100%;
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-size: 0.9rem;
    ::placeholder{

    }
    ::selection {
        background-color: #FF3A47;
    }
    :focus {
        ${(props) => props.error ? `outline: solid 2px #FF3A47;` : "outline: none"}
    }
    margin-top: 15px;
    ${(props) => props.error && `outline: solid 2px #FF3A47;`}

    @media (max-width: 600px) {
        width: 90%;
    }
`;

const ErrorText = styled.p`
    color: #FF3A47;
    width: 600px;
    text-align: left;
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
    margin-top: 3px;
    margin-bottom: 15px;
    @media (max-width: 600px) {
        width: 90%;
    }
`;