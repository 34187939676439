import { useState, useEffect, useRef } from 'react';
import '../App.css';
import styled from 'styled-components';

import { useParams } from 'react-router-dom';
import { motion, useScroll, useTransform} from "framer-motion"



export default function EventScreen() {

    const [scrollPosition, setScrollPosition] = useState(0);

    const scrollRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: scrollRef,
        offset: [0, 1]
    });
    const y = useTransform(scrollYProgress, [0, 1], ["0%", "-100%"]);

    const opacity = useTransform(scrollYProgress, [0, 0.5], [1.01, 0]);

    const { id } = useParams();

    return (
        <EverythingWrapper>
            <ContentWrapper red={scrollRef}>
                <motion.div animate={{opacity: opacity}} transition={{duration: 1}} 
                    style={{display: "flex", flexDirection: "column", alignItems: "center"}}
                >
                    <Toptitle>webversion not available</Toptitle>
                    <Title>HUMAN CONECTION <br /> <ColorHightlight>RE</ColorHightlight>DEFINED </Title>
                    <SubtitleWrapper>
                        <Subtitle>Find partys and Events near you, connect with new people and just have fun!</Subtitle>
                    </SubtitleWrapper>
                    <Text>Download the app</Text>
                    <DownloadIconsWrapper>
                        <AppStoreIconWrapper href={"thenights://" + id}>
                            <AppStoreIcon src={require("../assets/images/getAppApple.png")} alt="apple store icon" />
                        </AppStoreIconWrapper>
                        <GooglePlayIconWrapper href={"thenights://" + id}>
                            <GooglePlayIcon src={require("../assets/images/getAppGoogle.png")} alt="google play icon" />
                        </GooglePlayIconWrapper>
                    </DownloadIconsWrapper>
                </motion.div>

                <motion.div
                    style={{ 
                        y,
                        display: "flex",
                        width: "100%",
                     }}
                >
                    <SimulatorImage src={require("../assets/images/AppMockupText.png")} />
                    <DescriptiveContainer
                        style={{
                            position: "absolute",
                            top: "25%",
                            left: "6%",
                        }}
                    >
                        <DescriptiveTitle>Plan your next Event</DescriptiveTitle>
                        <DescriptiveText>Spent less time planing and more time partying. We offer tools to help you, when you want to get together with friends.</DescriptiveText>
                    </DescriptiveContainer>
                    <DescriptiveContainer
                        style={{
                            position: "absolute",
                            top: "45%",
                            right: "6%",
                        }}
                    >
                        <DescriptiveTitle>Meet new people</DescriptiveTitle>
                        <DescriptiveText>Make friends for live or just have a great time. Create or find public events to get to know more people.</DescriptiveText>
                    </DescriptiveContainer>
                    <DescriptiveContainer
                        style={{
                            position: "absolute",
                            top: "65%",
                            left: "6%",
                        }}
                    >
                        <DescriptiveTitle>Plan your next Event</DescriptiveTitle>
                        <DescriptiveText>Save the hustle and find Clubs, Concerts and Festivals with ease. We support a growing number of partners near you. </DescriptiveText>
                    </DescriptiveContainer>
                </motion.div>
            </ContentWrapper>
        </EverythingWrapper>
    );
}

const EverythingWrapper = styled.div`    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    background-color: #101010;

    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ContentWrapper = styled.div`
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Toptitle = styled.p`
    font-family: 'Heebo';
    font-weight: 400;
    font-size: 1rem;
    color: #6E6E6E;
`;

const Title = styled.p`
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'NeueHaasGroteskBold';
    font-size: 5rem;
    text-align: center;
    color: #fff;
`;

const SubtitleWrapper = styled.div`
    margin-top: 1.5rem;
    width: 60%;
`;

const Subtitle = styled.p`
    margin-top: 0px;
    font-family: 'Heebo';
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
    color: #fff;
`;

const ColorHightlight = styled.span`
    color: #FF3A47;
`;

const Text = styled.p`
    font-family: 'Heebo';
    font-weight: 200;
    font-size: 1.2rem;
    color: #fff;
`;

const DownloadIconsWrapper = styled.div`
    margin-top: 3rem;

    height: 5vh;

    display: flex;
    flex-direction: column;
    align-items: center;

    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const AppStoreIconWrapper = styled.a`
    margin-right: 2vh;
`;

const AppStoreIcon = styled.img`
    height: 80px;
`;

const GooglePlayIconWrapper = styled.a`
`;

const GooglePlayIcon = styled.img`
    height: 80px;
`;

const SimulatorImage = styled.img`
    margin-top: 10vh;
    width: 66%;
    margin-left: 50%;
    transform: translateX(-50%);
`;

const DescriptiveContainer = styled.div`
    width: 20%;
`;

const DescriptiveTitle = styled.p`
    font-family: 'Heebo';
    font-weight: 700;
    font-size: 1.8rem;
    color: #fff;
`;

const DescriptiveText = styled.p`
    font-family: 'Heebo';
    font-weight: 400;
    font-size: 1.2rem;
    color: #fff;
`;