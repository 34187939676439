import { useState, useEffect, useRef } from 'react';
import '../App.css';
import styled from 'styled-components';

import { useParams } from 'react-router-dom';
import { motion, useScroll, useTransform} from "framer-motion"


const VerifyEmailScreen = ({ navigation }) => {

    const { uidb64, token } = useParams();
    console.log("uidb64: " + uidb64 + " token: " + token);

    const [title, setTitle] = useState("");
    const [subtitle, setSubtitle] = useState("");

    useEffect(() => {
        const sendRequest = async () => {
            try {
                const response = await fetch(`https://api.thenights.app/user_manager/activateapi`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        uidb64: uidb64,
                        token: token,
                    })
                });
                console.log(JSON.stringify(response.status));
                if(response.status === 200) {
                    setTitle("Your Email has been verified");
                    setSubtitle("You have successfully verified your email.\nThanks for using our platform!");
                } else {
                    setTitle("Something went wrong");
                    setSubtitle("It looks like the link you clicked on is invalid. If your email is not yet verified you can send a new verification link from your Account settings. ");
                }
                const responseData = await response.json();
                console.log(responseData);

            } catch (err) {
                console.log(err);
            }
        };
        sendRequest();
    }, []);

    return (
        <EverythingWrapper>
            <CenterPanel
                initial={{transform: "translateY(-20vw)", opacity: 0}}
                animate={{transform: "translateY(0vw)", opacity: 1}}
                exit={{transform: "translateY(20vw)", opacity: 0}}
            >
                <CenterPanelContentWrapper>
                    <PanelTitle>{title}</PanelTitle>
                    <PanelSubtitle>{subtitle}</PanelSubtitle>
                </CenterPanelContentWrapper>
            </CenterPanel>
        </EverythingWrapper>
    );
}

export default VerifyEmailScreen;

const EverythingWrapper = styled.div`
    background-color: #101010;
    witdh: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CenterPanel = styled(motion.div)`
    position: relative;
    background-color: #191919;
    width: 30%;
    min-width: 580px;
    max-width: 620px;
    height: 70%;
    min-height: 800px;
    max-height: 900px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 600px) {
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
`;

const CenterPanelContentWrapper = styled.div`
    width: 85%;
    height: 90%;

    justify-content: center;
    @media (max-width: 600px) {
        width: 80%;
        height: 100%;
        border-radius: 0;
    }
`;

const PanelTitle = styled.h1`
    color: #FF3A47;
    color: #fff;
    font-family: 'Heebo';
    font-weight: 600;
    font-size: 1.5rem;
    width: 100%;
    text-align: left;
    text-transform: uppercase;
`;

const PanelSubtitle = styled.h2`
    color: #fff;
    font-family: 'Heebo';
    font-weight: 400;
    font-size: 1rem;
    width: 100%;
    text-align: left;
`;