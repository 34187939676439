import React from "react";

import SharingText from "../../components/SharingText";

const TestingPitch_19_07_23 = () => {

    return(
        <SharingText
            headlne="Testing Pitch"
            subheadline="July 23, 2019"
            text="This is a test of the sharing text component."
        />
    )
}

export default TestingPitch_19_07_23;