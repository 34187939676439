import React from "react";
import styled from "styled-components";

const SharingText = ({ 
    headlne,
    subheadline,
    text
}) => {

    return(
        <SharingTextWrapper>
            <SharingTextHeadline>{headlne}</SharingTextHeadline>
            <SharingTextSubheadline>{subheadline}</SharingTextSubheadline>
            <SharingTextText>{text}</SharingTextText>
        </SharingTextWrapper>
    )
}

export default SharingText;

const SharingTextWrapper = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #101010;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

const SharingTextHeadline = styled.h1`
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 1rem;
`;

const SharingTextSubheadline = styled.h2`
    font-size: 1.5rem;
    color: #fff;
    margin-bottom: 1rem;
`;

const SharingTextText = styled.p`
    font-family: 'Heebo';
    font-weight: 200;
    font-size: 1rem;
    color: #fff;
`;